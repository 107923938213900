
export const ImgGallery = ()=>{
    return <div className="gallery">
        <img className="gallery__img" src={require('../img/cake7.jpg')}/>
        <img className="gallery__img" src={require('../img/cake8.jpg')}/>
        <img className="gallery__img" src={require('../img/cake1.jpg')}/>
        <img className="gallery__img" src={require('../img/cake2.jpg')}/>
        <img className="gallery__img" src={require('../img/cake3.jpg')}/>
        <img className="gallery__img" src={require('../img/cake5.jpg')}/>
        <img className="gallery__img" src={require('../img/cake6.jpg')}/>

    </div>
}